.content {
    width: 96vw;
    height: 92vh;
    background-color: #000000;
}

.todayRegisterNum {
    font-size: 14px;
    left: 0px;
}

.allRegisterNum {
    font-size: 14px;
}

.todayLoginNum {
    font-size: 14px;
}

.allLoginNum {
    font-size: 14px;
}